import PlanningTool from '@/views/PlanningTool/PlanningTool.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component<PlanningToolImmaterieel>({
  components: {
    PlanningTool,
  },
})
export default class PlanningToolImmaterieel extends Vue {
  public $pageTitle = 'Plannningtool immaterieel';
}
